import type React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface AudioPlayerProps {
  src: string;
}

const PlayerContainer = styled.div`
  background-color: rgba(133, 126, 113, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 300px;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const PlayPauseButton = styled.button`
  background-color: #857e71;
  border: none;
  border-radius: 50%;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6e6859;
  }
`;

const TimeDisplay = styled.span`
  color: #857e71;
  font-size: 14px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: rgba(133, 126, 113, 0.2);
  border-radius: 2px;
  cursor: pointer;
`;

const ProgressBar = styled.div<{ width: string }>`
  height: 100%;
  background-color: #857e71;
  border-radius: 2px;
  width: ${(props) => props.width};
`;

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);

    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioTime);

    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const audio = audioRef.current;
    const progress = progressRef.current;
    if (!audio || !progress) return;

    const bounds = progress.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const percent = x / bounds.width;
    audio.currentTime = percent * audio.duration;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <PlayerContainer>
      <audio ref={audioRef} src={src} />
      <ControlsContainer>
        <PlayPauseButton onClick={togglePlayPause}>
          {isPlaying ? "❚❚" : "▶"}
        </PlayPauseButton>
        <TimeDisplay>
          {formatTime(currentTime)} / {formatTime(duration)}
        </TimeDisplay>
      </ControlsContainer>
      <ProgressContainer ref={progressRef} onClick={handleProgressClick}>
        <ProgressBar width={`${(currentTime / duration) * 100}%`} />
      </ProgressContainer>
    </PlayerContainer>
  );
};

export default AudioPlayer;

import { twMerge } from "tailwind-merge";
import type { ReactNode } from "react";

interface TypographyProps {
  italic?: boolean;
  center?: boolean;
  uncolored?: boolean;
  children?: ReactNode;
}

const Typography = ({
  italic,
  center,
  children,
  className,
  as: Component = "p",
}: TypographyProps & {
  className?: string;
  as?: "p" | "h1" | "h2" | "label";
}) => {
  return (
    <Component
      className={twMerge([
        italic && "italic",
        center && "text-center",
        className,
      ])}
    >
      {children}
    </Component>
  );
};

export const Paragraph = (props: TypographyProps) => (
  <Typography
    {...props}
    className={props.center ? "text-center" : "text-justify"}
  />
);

export const Title = (props: TypographyProps) => (
  <Typography
    {...props}
    as="h1"
    className={`${
      !props.uncolored && "text-[#857e71]"
    } pb-4 pt-1 group-hover:cursor-pointer group-hover:underline text-xl`}
  />
);

export const Subtitle = (props: TypographyProps) => (
  <Typography
    {...props}
    as="h2"
    className={`${!props.uncolored && "text-[#857e71]"} text-lg`}
  />
);

export const Label = ({ children }: { children: ReactNode }) => (
  <Typography as="label" className="text-slate-400">
    {children}
  </Typography>
);

// TOOO: Make this some other way and more flexible
export const Divider = ({ center }: TypographyProps) => (
  <Typography className={`${center && "mx-auto w-fit"}`} as="p">
    .............................................
  </Typography>
);

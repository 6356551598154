import { Page } from "../components/Page";

export const IdeasPage = () => {
  return (
    <Page
      className="font-serif text-center"
      customOpacity={70}
      backgroundSrc="/assets/background.jpg"
    >
      <h1 className="z-10 mt-20 text-2xl text-gray-500 md:text-3xl">
        Veškerá společně konaná setkání budou oznámena včas
      </h1>
      <h3 className="text-lg text-gray-500">
        Stránky Hlasy Země jsou v počátečním zrodu a všechno následující se bude
        postupně vyvíjet společně s ostatními
      </h3>
    </Page>
  );
};

import { ContentWrapper } from "./articles/components/ContentWrapper";
import { Paragraph, Subtitle, Title } from "./articles/components/Typography";

export const LandingPageArticle = () => {
  return (
    <ContentWrapper>
      <Subtitle center>CHCEŠ-LI TAKÉ</Subtitle>

      <Paragraph center>
        s námi hledat cesty, jak ve Zlíně a jeho okolí probudit k životu
        harmonii a napomáhat sjednotit lidi toužící po vzájemné podpoře a důvěře
      </Paragraph>
      <Paragraph center>
        Společně produchovnět náš prostor a sdílet vyšší lidské hodnoty mezi
        těmi
      </Paragraph>
      <Paragraph center>
        kteří prahnou po koruně vroucí ušlechtilosti.
      </Paragraph>

      <Subtitle center>VIDÍŠ-LI TAKÉ, ŽE</Subtitle>
      <Paragraph center>
        náš prostor je čím dál více budován na základě hmotného uspokojení
      </Paragraph>
      <Paragraph center>
        Svět, který známe prochází velkou přeměnou a každého z nás
      </Paragraph>
      <Paragraph center>probouzí k nalezení Pravdy</Paragraph>

      <Subtitle center>JSI-LI TEN, KDO TOUŽÍ PO</Subtitle>
      <Paragraph center>harmonii kolem nás</Paragraph>
      <Paragraph center>
        Vzájemné důvěře a budování pevných společných mostů
      </Paragraph>
      <Paragraph center>které nám mohou dát pevnou půdu pod nohama</Paragraph>
      <Paragraph center>
        Sdílení lidí, kteří jsou schopni spolu otevřeně komunikovat
      </Paragraph>
      <Paragraph center>
        a pro které je touha po Pravdě tím nejvyšším cílem
      </Paragraph>

      <Subtitle center>HLEDÁME SDÍLENÍ I TVOŘENÍ S TĚMI, KTEŘÍ</Subtitle>
      <Paragraph center>
        jsou ještě schopni duchovně vnímat svět kolem nás
      </Paragraph>
      <Paragraph center>Usilují o harmonii práv a povinností</Paragraph>
      <Paragraph center>
        a jsou si vědomi zodpovědnosti za své myšlenky, slova a činy
      </Paragraph>
      <Paragraph center>
        Plně a s úctou respektují hranice druhých bez ohledu na jejich
      </Paragraph>
      <Paragraph center>společenské, politické a náboženské názory</Paragraph>
      <Paragraph center>a snaží se o naplnění hodnot jako</Paragraph>

      <Title center>HARMONIE - SJEDNOCENÍ - DŮVĚRA</Title>
    </ContentWrapper>
  );
};

import React, { useState } from "react";

export const JoinButton = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [emailLocked, setEmailLocked] = useState(false);

  const handleMouseEnter = () => {
    if (!emailLocked) {
      setShowEmail(true);
    }
  };

  const handleMouseLeave = () => {
    if (!emailLocked) {
      setShowEmail(false);
    }
  };

  const handleClick = () => {
    setEmailLocked(!emailLocked);
    setShowEmail(!emailLocked);
  };

  return (
    <div className="fixed z-40 bottom-4 right-4 w-[200px]">
      <div className="flex flex-col items-end">
        {(showEmail || emailLocked) && (
          <a href="mailto:hlasy-zeme@email.cz" className="w-full mb-2">
            <div className="bg-[#d8d0c1] text-[#857E71] p-2 text-center">
              hlasy-zeme@email.cz
            </div>
          </a>
        )}

        <button
          className="bg-[#b2aca0] block hover:opacity-90 p-2 text-white w-full"
          type="button"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <p>Chcete se přidat?</p>
          <p>kontaktujte nás</p>
        </button>
      </div>
    </div>
  );
};

import { Page } from "../components/Page";

export const ContactPage = () => {
  return (
    <Page
      customOpacity={75}
      backgroundSrc="https://files.catbox.moe/c83i9s.jpg"
    >
      <div className="z-20 mx-auto mt-[100px] text-[#857e71] text-center w-fit">
        <p className="text-2xl text-black">Email</p>
        <a href="mailto:hlasy-zeme@email.cz">
          <p className="text-3xl">hlasy-zeme@email.cz</p>
        </a>
      </div>
    </Page>
  );
};

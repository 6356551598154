import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useMemo } from "react";
import data from "../data/articles.json";

interface ArticleShowProps {
  articleCount?: number;
}

export const ArticleShow = ({ articleCount }: ArticleShowProps) => {
  const articles = useMemo(
    () => (articleCount ? data.slice(0, articleCount) : data),
    [articleCount]
  );

  return (
    <div
      className={twMerge([
        "grid grid-cols-1 gap-5",
        data.length > 1 && "md:grid-cols-2",
        data.length > 2 && "lg:grid-cols-3",
      ])}
    >
      {articles.map((article) => {
        return (
          <Link
            to={`/article/?id=${article.id}`}
            key={article.id}
            className="group"
          >
            <div className="bg-[#857e71]/10 cursor-pointer w-fit p-3">
              <label className="text-slate-400">
                {new Date(article.createdAt).toLocaleDateString("cz-CZ")}
              </label>
              <h1 className="text-[#857e71] group-hover:cursor-pointer group-hover:underline text-xl">
                {article.title}
              </h1>
              <p className="w-fit max-w-[500px] mt-1.5">
                {article.content.substring(0, 100)}
              </p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

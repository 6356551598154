import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";

const LINKS = [
  { href: "/", label: "Domů" },
  { href: "/new-today", label: "Nové dneška I." },
  { href: "/work-meaning", label: "Smysl naši práce II." },
  { href: "/articles", label: "Články a audio" },
  { href: "/ideas", label: "Zlín a dění" },
  { href: "/thanks", label: "Poděkování" },
  { href: "/kontakt", label: "Kontakt" },
];

const NavbarLinks = () => (
  <ul className="flex-row items-center justify-center hidden w-full gap-2.5 lg:flex">
    {LINKS.map(({ href, label }) => (
      <li key={`${href}${label}`} className="mx-3">
        <a href={href} className="hover:text-slate-800 text-[16px]">
          {label}
        </a>
      </li>
    ))}
  </ul>
);

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="relative z-30 w-full bg-white border-t-[2.75px] border-gray-300 shadow-xl text-slate-500">
      <div className="container px-4 mx-auto">
        <div className="flex items-center justify-between h-11">
          <button
            type="button"
            className="mx-auto w-fit lg:hidden"
            onClick={toggleMenu}
          >
            <RxHamburgerMenu className="w-6 h-6" />
          </button>

          <NavbarLinks />
        </div>
      </div>

      {isMenuOpen && (
        <div className="lg:hidden">
          <ul className="flex flex-col items-center py-4">
            {LINKS.map(({ href, label }) => (
              <li key={`${href}${label}`} className="my-2 text-lg">
                <a
                  href={href}
                  className="hover:text-slate-800"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

import type React from "react";
import { useCallback, useState } from "react";
import { Page } from "../components/Page";

interface Photo {
  id: number;
  src: string;
  alt: string;
}

const photos: Photo[] = Array.from({ length: 9 }, (_, index) => ({
  id: index + 1,
  src: `/assets/photogallery/foto${index + 1}.jpg`,
  alt: `Photo ${index + 1}`,
}));

export const Photogallery: React.FC = () => {
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);

  const openPhoto = useCallback((photo: Photo) => setSelectedPhoto(photo), []);
  const closePhoto = useCallback(() => setSelectedPhoto(null), []);

  return (
    <Page noVerticalPadding>
      {selectedPhoto && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <button
            type="button"
            className="absolute inset-0 bg-black opacity-50"
            onClick={closePhoto}
          />
          <div className="relative bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] overflow-auto">
            <img
              src={selectedPhoto.src}
              alt={selectedPhoto.alt}
              className="object-contain max-w-full max-h-full"
            />
            <button
              type="button"
              className="absolute text-gray-600 top-2 right-2 hover:text-gray-800"
              onClick={closePhoto}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="grid max-w-6xl grid-cols-1 gap-4 px-4 mx-auto sm:grid-cols-2 lg:grid-cols-3">
        {photos.map((photo) => (
          <button
            type="button"
            key={photo.id}
            className="transition-opacity cursor-pointer aspect-square hover:opacity-80"
            onClick={() => openPhoto(photo)}
          >
            <img
              src={photo.src}
              alt={photo.alt}
              className="object-cover w-full h-full rounded-lg"
            />
          </button>
        ))}
      </div>
    </Page>
  );
};

import { twMerge } from "tailwind-merge";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  backgroundFixed?: boolean;
  backgroundSrc?: string;
  noVerticalPadding?: boolean;
  customOpacity?: number;
}

export const Page = ({
  children,
  backgroundFixed,
  backgroundSrc,
  noVerticalPadding,
  customOpacity,
  ...props
}: PageProps) => {
  const styles = backgroundSrc
    ? {
        backgroundImage: `url("${backgroundSrc}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: backgroundFixed ? "fixed" : "scroll",
      }
    : {};

  return (
    <div
      {...props}
      className={twMerge(["relative min-h-screen mb-20", props.className])}
      id="article"
    >
      <div className="fixed inset-0 z-0" style={styles} />
      <div
        className="fixed inset-0 z-10 bg-white"
        style={{
          opacity: customOpacity ? `${customOpacity}%` : "60%",
        }}
      />

      <div
        className={twMerge([
          "relative z-20 px-10 lg:px-20 space-y-2.5",
          !noVerticalPadding && "py-10 lg:py-20",
        ])}
      >
        {children}
      </div>
    </div>
  );
};
